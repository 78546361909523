import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import InvoiceDataRows from 'components/Core/Invoice/InvoiceDataRows/InvoiceDataRows';
import InvoiceSummary from 'components/Core/Invoice/Summary/InvoiceSummary';
import Hr from 'components/Shared/Hr/Hr';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { insightActions } from 'store/insight/slice';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';

interface InvoiceToPay {
  docNumber?: string;
  link?: string;
  dueDate?: string;
  balance: number;
  partiallyPaid?: boolean;
}

export interface BulkInvoiceInfoProps {
  currency?: string;
  featureFlags?: FeatureFlags;
  gratuityValue: number;
  invoicesToPay?: InvoiceToPay[];
}

const BulkInvoiceInfo = (props: BulkInvoiceInfoProps) => {
  const { gratuityValue = 0, currency, invoicesToPay } = props;

  const isMobile = useIsMobileScreen(768);
  const hrColor = isMobile ? colors.gray06 : colors.offWhite;
  const dispatch = useDispatch();
  const invoicesCount = invoicesToPay?.length;

  return (
    <Fragment>
      <div className="invoice-bulk-info">
        <Hr marginTop={10} marginBottom={10} borderColor={colors.offWhite} opacity={1} />
        <div className="invoice-selection">
          <span className="invoice-selection--num">
            <FormattedMessage
              id="BULK_PAYMENTS_SELECTED_INVOICES"
              defaultMessage="{invoicesCount} selected invoices"
              values={{
                invoicesCount,
              }}
            />
          </span>
          <span
            className="invoice-selection--edit"
            onClick={() => dispatch(insightActions.updateIsBulkModalOpen(true))}
          >
            <FormattedMessage id="BULK_PAYMENTS_EDIT_SELECTION" defaultMessage="Edit selection" />
          </span>
        </div>
        <Hr marginTop={10} marginBottom={0} borderColor={colors.offWhite} opacity={1} />
        {invoicesToPay?.map(({ docNumber, link, dueDate, balance, partiallyPaid }, i) => {
          return (
            <Fragment key={`invoice-item-${i}`}>
              <ul className="invoice-list-info" aria-label="invoice info list">
                <InvoiceSummary
                  invoiceNumber={
                    <a href={link} target="_blank" rel="noreferrer" className="invoice-link">
                      {docNumber}
                    </a>
                  }
                  invoiceDueDate={dueDate}
                  invoiceAmount={balance}
                  shouldShowInvoiceLineItems={false}
                  currency={currency}
                  gratuityValue={gratuityValue}
                  hrColor={hrColor}
                />
                <InvoiceDataRows
                  currency={currency}
                  isPartiallyPaid={partiallyPaid}
                  isFullyPaid={false}
                  invoiceAmount={balance}
                />
              </ul>
              <Hr marginTop={8} marginBottom={0} borderColor={colors.offWhite} opacity={1} />
            </Fragment>
          );
        })}
      </div>
      <style jsx>{`
        .invoice-bulk-info {
          padding: 0;
          margin: 0 0 10px 0;
          max-height: 525px;
          overflow: auto;

          @media (max-width: ${breakpoints.md}) {
            max-width: 400px;
            margin: 5px auto;
          }
          @media (max-width: ${breakpoints.sm}) {
            max-width: 576px;
          }

          .invoice-selection {
            display: flex;
            justify-content: space-between;
            font-size: ${fontSize.xs};

            &--num {
              font-family: AvenirNextforINTUIT-Demi;
            }

            &--edit {
              color: ${colors.blue05};
              cursor: pointer;
            }
          }

          .invoice-list-info {
            margin: 0;
            padding: 0;

            .invoice-link {
              color: ${colors.blue05};
              text-decoration: none;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default BulkInvoiceInfo;
